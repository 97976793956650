import "./DeOchtend.css";
import {Parallax} from "react-parallax"

function DeOchtend(prop){
    function appear(x,start,end){
        return Math.sin((x-start)*3.5/(end-start))*1.25;
    }

    function appear2(x,start,end){
        if (x<1){
            return 0
        }
        else{
            return Math.sin((x-start)*3.5/(end-start))*1.25;
        }
        
    }


    function controller(x){
        if(x<0.9){
            return 0
        }
        if(x>0.9 && x<1.15){
            return (x-0.9)*250;
        }
        else
         return (1.15-0.9)*250
     }

     function tactiek(x){
        if(x<1.1){
            return 0
        }
        if(x>1.1 && x<1.3){
            return (x-1.1);
        }
        else
         return (1.2-1.0);
     }
    

    return(
        <div id={prop.id}>
          
            <Parallax
                className="voorbereiding-container"
                renderLayer = {percentage =>(
                    <div>
                        <img
                            src="images/AtanoVoorbereiding.png"
                            className="voorbereiding-controller"
                            alt="Atano controller"
                            style = {{ 
                                transform: `translate(-50%,${controller(percentage)}%) `, 
                                }}
                        />
                        <h1>
                            <div
                                className="main-text"
                                style = {{ 
                                    transform: `translate(-50%,0) scale(${0.9+0.1*appear(percentage,0.5,1.3)})`, 
                                    opacity:`${appear(percentage,0.5,1.3)}` 
                                    }}
                            >
                                <h2>De ochtend</h2>
                               In de ochtend bepalen we onze tactiek voor die dag
                            </div>
                            <div
                                className="voorbereiding-tijd"
                                style = {{ 
                                    transform: `scale(${0.9+0.1*appear2(percentage,1,1.4)})`, 
                                    opacity:`${appear2(percentage,1,1.4)}`
                                    }}
                            >
                                Iedere ochtend overwegen we of we meer tijd in de werkplaats willen zijn of 
                                dat we juist eerder op de toren willen staan om draken te kunnen vangen.

                            </div>
                            <div
                                className="voorbereiding-taktiek"
                                style = {{ 
                                    transform: `translate(-100%,${tactiek(percentage)*180}vh) scale(${0.9+0.1*appear(percentage,1.2,1.85)})`, 
                                    opacity:`${appear(percentage,1.2,1.85)}`
                                    }}
                            >
                                Je vangt draken, maar je bevecht de andere speler. 
                                In deze strijd zijn er verschillende tacktieken mogelijk om je voorsprong
                                 ten opzichte van de anderen te vergroten of te behouden.
                            </div>
                            
                        </h1>
                        
                    </div>
                )}
            />
        </div>
    )
}

export default DeOchtend;