import "./Intro.css";
import { Parallax } from 'react-parallax'



function Intro(prop){

   function appear(x){
       return Math.sin((x-0.3)*3.5);
   }
    return(
        <div id={prop.id}>
        <Parallax
        className="intro-container"  
        renderLayer = {percentage =>(
            <div>
                <div 
                    className="intro-text"
                    style = {{ 
                        transform: `translate(0,${percentage*90}%) scale(${1+0.1*appear(percentage)})`, 
                        opacity:`${appear(percentage)}`
                    }}
                >
                Elk jaar komen ze opnieuw. 
                We vangen deze mormels en proberen zo de held van het dorp Atano te worden.
                </div>   
                <img 
                    src="images/AtanoIntro01.png" 
                    className="intro-picture" 
                    alt="Atano intro"
                    style = {{ 
                        transform: `translate(70%,5%) scale(${0.5+0.4*percentage})`, 
                    }}

                />
            </div>

        )}
        /> 
        </div>
    )
}

export default Intro;