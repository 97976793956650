import "./Intro.css";


function Titel(prop){
    return(
        <div className="titel-container" id={prop.id}>
            <video src='videos/Atano2.mp4' className="video" autoPlay loop muted alt= "Atano Video" />
            <img src="images/atanofoto01.png"  className="titel-bordspel" alt= "Atano Bordspel"/>
            <div className="titel-text-container">
                <div className="titel-text">
                Atano is een bordspel waarin het dorp Atano tot leven komt. 
                Een dorpje waar de bewoners  vooral bezig zijn met het bouwen van inventieve machines waarmee ze draken vangen. 
                Iedere speelronde is een dag in het dorp Atano. Hieronder volgt een kort verslag van zo'n typische dag. 
                </div>
            </div>
        </div>    
    )
}

export default Titel;