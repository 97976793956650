import React, { useState, useEffect } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import "./NavBar.css"

function NavBar(){
    const scrollToTop = () => {
        scroll.scrollToTop();
      };

      const [click, setClick] = useState(false);
      const [button, setButton] = useState(true);
    
      const handleClick = () => setClick(!click);
      const closeMobileMenu = () => setClick(false);
    
      const showButton = () => {
        if (window.innerWidth <= 960) {
          setButton(false);
        } else {
          setButton(true);
        }
      };
    
      useEffect(() => {
        showButton();
      }, []);
    
      window.addEventListener('resize', showButton);

    return(
        <div className= {click ? "navbar-enabled":"navbar-disabled"}>
            <div className="blackStroke"/>
             <img
            src="images/logo_Atano.png"
            className={click? "navbar-logo-menuOn": "navbar-logo-menuOff"}
            alt="Logo"
            onClick={scrollToTop}
            />
            <div className={click ? "navbar-container-enabled":"navbar-container-disabled"}>
                <Link
                    className='navbar-link-dark'
                    activeClass="active"
                    to="Intro"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={1500}
                    onClick={closeMobileMenu}
                >
                    Inleiding
                </Link>
                <Link
                    className='navbar-link'
                    activeClass="active"
                    to="DeOchtend"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={1500}
                    onClick={closeMobileMenu}
                >
                    De Ochtend
                </Link>
                <Link
                    className='navbar-link'
                    activeClass="active"
                    to="Overdag"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={1500}
                    onClick={closeMobileMenu}
                >
                    Overdag
                </Link>
                <Link
                    className='navbar-link'
                    activeClass="active"
                    to="DeNacht"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={1500}
                    onClick={closeMobileMenu}
                >
                    De Nacht
                </Link>
                <Link
                    className='navbar-link-dark'
                    activeClass="active"
                    to="Ontwerper"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={1500}
                    onClick={closeMobileMenu}
                >
                    Contact
                </Link>
                <div
                    className='navbar-socials'
                >
                    <img
                        src="images/Facebook.png"
                        className="navbar-Facebook"
                        alt="Logo"
                        onClick={()=> window.open("https://www.facebook.com/Atanospel/")}
                    />
                    <img
                        src="images/Instagram.png"
                        className="navbar-Instagram"
                        alt="Logo"
                        onClick={()=> window.open("https://www.instagram.com/atanospel/")}
                    />


                </div>
            </div>
            <img
            src="images/logo_Menu.png"
            className={click? "menuIcon-disabled":"menuIcon-enabled"}
            alt="Logo"
            onClick={handleClick}
            />
        </div>
    )

}

export default NavBar