import "./Overdag.css";
import { Parallax } from 'react-parallax'

function OverdagWapens(){
    return(
        <div className="torens-container">
        <Parallax
            className="torens-parallax"
            renderLayer={percentage =>(
            <div className="torens-imgclip">
                <div className="wapens-gradient"/>
                <img
                    src="images/AtanoOverdag04.png"
                    className="torens-sky2"
                    alt="Atano sky"
                />
                <img
                    src="images/AtanoOverdag06.png"
                    className="wapen02"
                    alt="Atano sky"
                    style = {{
                        transform: `translate(0,${percentage*15}%)`,
                        }}
                />
                <img
                    src="images/AtanoOverdag05.png"
                    className="wapen01"
                    alt="Atano wapen"
                />
            </div>
            )}
        />
    </div>
    )
}

export default OverdagWapens