import "./Intro.css";
import { Parallax} from 'react-parallax'

function Situatie(prop){
    function appear(x,start,end){
        return Math.sin((x-start)*3.5/(end-start))*1.25;
    }

    return(
        <div id={prop.id}>
            <Parallax
                className="situatie-container"
                renderLayer = {percentage =>(
                    <div>
                        <div className="situatie-plattegrond-container">
                            <img
                                src="images/AtanoSituatie01.png"
                                className="situatie-plattegrond"
                                alt= "Atano Plattegrond"
                            />
                            <img
                                src="images/AtanoSituatie02.png"
                                className="situatie-pijlen"
                                alt= "Atano route"
                            />
                        </div>
                          <div 
                                className="situatie-dorp"
                                style = {{ 
                                transform: `translate(-250px,0) scale(${0.9+0.1*appear(percentage,0.5,1.1)})`, 
                                opacity:`${appear(percentage,0.5,1.1)}`
                                }}
                            >
                                 Dit is het dorp Atano. Vanaf de kade hebben we goed zicht op alle aankomende mormels.
                            </div> 
                            <div 
                                className="situatie-route"
                                style = {{ 
                                transform: `translate(-250px,0) scale(${0.9+0.1*appear(percentage,0.8,1.4)})`, 
                                opacity:`${appear(percentage,0.8,1.4)}`
                                }}
                            >
                                 Vliegroute
                            </div>
                            <div 
                                className="situatie-drakeneiland"
                                style = {{ 
                                transform: `translate(-250px,0) scale(${0.9+0.1*appear(percentage,1.1,1.7)})`, 
                                opacity:`${appear(percentage,1.1,1.7)}`
                                }}
                            >
                                 Wij denken dat het mormeleiland er ongeveer zo uit zou moeten zien.
                            </div>
                    </div> 
                )}
            />
        </div>
    )
}

export default Situatie;