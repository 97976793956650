import "./components/App.css";
import NavBar from "./components/NavBar"
import Titel from "./components/Titel"
import Intro from "./components/Intro"
import Background from "./components/Background"
import Situatie from "./components/Situatie"
import DeOchtend from "./components/DeOchtend"
import DeOchtendTactiek from "./components/tactiek"
import Overdag from "./components/Overdag"
import OverdagTorens from "./components/OverdagTorens"
import OverdagWapens from "./components/OverdagWapens"
import Spacing from "./components/Spacing"

import DeNacht from "./components/DeNacht"
import Ontwerper from "./components/Ontwerper"


function App() {
  return (
    <div className="App">
      <NavBar/>
      <Background/>

      <Titel id="Titel"/>
      <Intro id="Intro"/>
      <Situatie id="Situatie"/>

      <DeOchtend id="DeOchtend"/>
      <DeOchtendTactiek/>

      <Overdag id="Overdag"/>
      <OverdagTorens/>
      <Spacing/>
      <OverdagWapens/>
      <Spacing/>

      <DeNacht id="DeNacht"/>

      <Ontwerper id="Ontwerper"/>

  </div>
  );
}

export default App;
