import "./Overdag.css";
import { Parallax } from 'react-parallax'

function Overdag(prop){


    function appear(x,start,end,piek){
        var a =-Math.abs((x-0.5*(end-start)-start)-(x-0.5*(end-start)-start)%((end-start)/2))*100;
        var b = Math.sin((x-0.25*(1-start/end)*end-start)*6.28/(end-start));
        var c = (b+1)/piek;

        return (Math.abs(a+c)+(a+c))/2;
    }
    return(
        <div id={prop.id}>
            <Parallax
                className="overdag-container"
                renderLayer={percentage =>(
                    <div>
                        <h1>
                         <div
                                className="main-text"
                                style = {{ 
                                    transform: `translate(-50%,0) scale(${0.9+0.1*appear(percentage,0.5,1.3)})`, 
                                    opacity:`${appear(percentage,0.5,1.3,1.4)}` 
                                    }}
                            >
                                <h2>Overdag</h2>
                                Overdag bouwen we onze torens en maken we onze wapens.
                            </div>
                        </h1>
                    </div>
                )}
            />
        </div>
    )
}

export default Overdag