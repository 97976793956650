import "./DeOchtend.css";
import { Parallax } from 'react-parallax'

function Tactiek(){


    function appear(x,start,end,piek){
        var a =-Math.abs((x-0.5*(end-start)-start)-(x-0.5*(end-start)-start)%((end-start)/2))*100;
        var b = Math.sin((x-0.25*(1-start/end)*end-start)*6.28/(end-start));
        var c = (b+1)/piek;

        return (Math.abs(a+c)+(a+c))/2;
    }

    function show(x){
        if (x>1.9)
        return false;
        else 
        return true;
    }
    return(
        <div>
            
            <Parallax
                className="tactiek-container"
                renderLayer={percentage =>(
                    <div className ={show(percentage)? "display-on": "display-off"} >
                        <div className="taktiek-gradient"/>
                        <img
                            src="images/AtanoTactiek02.png"
                            className="tactiek-logo"
                            alt="Atano Logo"
                            style = {{  
                                opacity:`${appear(percentage,0.6,0.9,1.4)}`
                                }}
                        />
                        <img
                            src="images/AtanoTactiek05.png"
                            className="tactiek5"
                            alt="Atano Logo"
                            style = {{  
                                opacity:`${appear(percentage,0.6,0.9,2)}`,
                                transform: `translate(-85%,${percentage*10}%)`, 
                                }}
                        />
                        <img
                            src="images/AtanoTactiek06.png"
                            className="tactiek6"
                            alt="Atano Logo"
                            style = {{  
                                opacity:`${appear(percentage,0.6,0.9,1.4)}`,
                                transform: `translate(-15%,${percentage*10}%)`, 
                                }}
                        />
                        <img
                            src="images/AtanoTactiek01.png"
                            className="tactiek-logo"
                            alt="Atano Logo"
                            style = {{  
                                opacity:`${appear(percentage,0.8,1.1,1.4)}`
                                }}
                        />
                        <img
                            src="images/AtanoTactiek09.png"
                            className="tactiek9"
                            alt="Atano Logo"
                            style = {{  
                                opacity:`${appear(percentage,0.8,1.1,2)}`,
                                transform: `translate(${+20-percentage*35}%,0%)`, 
                                }}
                        />
                        <img
                            src="images/AtanoTactiek03.png"
                            className="tactiek-logo"
                            alt="Atano Logo"
                            style = {{  
                                opacity:`${appear(percentage,1.0,1.3,1.4)}`
                                }}
                        />
                          <img
                            src="images/AtanoTactiek10.png"
                            className="tactiek10"
                            alt="Atano Logo"
                            style = {{  
                                opacity:`${appear(percentage,1.05,1.55,1.4)}`,
                                transform: `translate(-50%,${percentage*100}%)`, 
                                }}
                        />
                    </div>
                )}
            />
        </div>
    )
}

export default Tactiek