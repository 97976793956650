import "./DeNacht.css";
import { Parallax } from 'react-parallax'

function DeNacht(prop){
    function appear(x,start,end){
        var a =-Math.abs((x-0.5*(end-start)-start)-(x-0.5*(end-start)-start)%((end-start)/2))*100;
        var b = Math.sin((x-0.25*(1-start/end)*end-start)*6.28/(end-start));
        var c = (b+1)/1.4;

        return (Math.abs(a+c)+(a+c))/2;
    }
    return(
        <div id={prop.id}>
        
        <Parallax
        className="denacht-container"
        renderLayer={percentage=>(
            <div>
                <img src="images/DeNacht01.jpg" 
            className="denacht-achtergrond"
            alt="atano-achtergrond"
            />
                <h1>
                    <div className="main-text"
                        style = {{  
                        transform: `translate(-50%,0) scale(${0.9+0.1*appear(percentage,0.7,1.2)})`, 
                        opacity:`${appear(percentage,0.7,1.2)}`  
                    }}
                    >
                        <h2>De Nacht</h2>
                        In de Nacht staat iedereen op zijn toren en bewijzen we dat we echte helden zijn.
                    </div>
                </h1>
                <img
                    src="images/DeNacht02.png"
                    className="denacht-personen"
                    alt="Atano de nacht"
                    style = {{  
                        transform: `translate(${25-percentage*30}%,${25-percentage*25}%) scale(${0.4+percentage*0.8},${0.4+percentage*0.8})`, 
                        filter:`blur(${percentage*1}px)`    
                    }}
                />
                <img
                    src="images/DeNacht03.png"
                    className="denacht-draak"
                    alt="Atano d"
                    style = {{  
                        transform: `translate(${-25+percentage*20}%,0) scale(${0.6+percentage*0.4},${0.6+percentage*0.4})`, 
                        opacity:`${appear(percentage,0.6,1.9)}`  
                    }}
                />
                

            </div>
        )}
        />
        
    </div>
    )
}

export default DeNacht;