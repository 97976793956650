import "./Overdag.css";
import { Parallax } from 'react-parallax'

function OverdagTorens(){
    return(
        <div className="torens-container">
            <Parallax
                className="torens-parallax"
                renderLayer={percentage =>(
                <div className="torens-imgclip">
                    <div className="torens-vignet"/>
                    <img
                        src="images/AtanoOverdag01.png"
                        className="torens-sky"
                        alt="Atano sky"
                    />
                    <img
                        src="images/AtanoOverdag03.png"
                        className="torens-achter"
                        alt="Atano sky"
                        style = {{  
                            transform: `translate(-50%,${percentage*50}%)`, 
                            }}
                    /> 
                    <img
                        src="images/AtanoOverdag02.png"
                        className="torens-voor"
                        alt="Atano sky"
                    /> 
                </div> 
                )}
            />
        </div>
    )
}

export default OverdagTorens