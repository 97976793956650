import "./Ontwerper.css";

function Ontwerper(prop){


    return(
        <div id={prop.id} className="Ontwerper-container">
            <div className="text-container">
            <img
                    src="images/Atano_logo2.png"
                    className="Ontwerper-logo"
                    alt="logo"
                />


            <div className="Line"/>
                <div className="text-center">
                    Marijn Vos <br/>
                    06 23640882<br/>
                    mail@marijnvos.nl
                </div>
                <div className='navbar-socials'>
                    <img
                        src="images/Facebook.png"
                        className="Facebook"
                        alt="Logo"
                        onClick={()=> window.open("https://www.facebook.com/Atanospel/")}
                    />
                    <img
                        src="images/Instagram.png"
                        className="Instagram"
                        alt="Logo"
                        onClick={()=> window.open("https://www.instagram.com/atanospel/")}
                    />
                </div>
                <div className="Line"/>
                <div>
                    Na mijn studie als architect ben ik in de festivalwereld terechtgekomen en werk ik nu als stagedesigner voor Twofiftyk.  Een internationaal design bureau dat over de hele wereld shows ontwerpt en regiseert voor de grotere dancefeesten. Jaren geleden ben ik ooit een persoonlijk project gestart genaamd Atano.  
<br/><br/>
Wat me fascineert als stagedesigner en wat me is bijgebracht als architect is de schoonheid die onstaat in de wisselwerking tussen techniek en het verhaal/ het gewenste funtioneren dat je wilt uitdragen.  In een constante dialoog zoek ik hoe techniek onderdeel wordt van de schoonheid van het gebouw of podium en bevraag ik hoe de gekozen esthetische vorm het gewenste functioneren kan versterken. Dit dialoog heb ik proberen toe te passen in het ontwerpen  van een bordspel. 
<br/><br/>
In dit proces heb ik constant gezocht welke spelmechanismen kunnen bijdragen aan het verhaal waar je de speler in wilt meenemen. Tegelijkertijd heb ik bekeken hoe dat verhaal het gewenste spelmechansime kan verduidelijken. Ik heb tientallen bordenvariaties, controllers en statieven ontworpen en daarmee bekeken hoe het fuctioneren logisch en helder kan aanvoelen. Daarbij heb ik het verhaal vormgegeven zodat het je blijft intrigeren. Ooit begonnen met een complexe set aan regels, deze vervolgens teruggebracht tot de essentie om vervolgens weer regels toe te voegen. Zo heb ik geprobeerd tot een punt te komen waarin de ervaren speler uitgedaagd blijft en de beginnende speler niet wordt afgeschrikt. Ik heb gezocht hoe ook de doos en spelregels onderdeel kunnen vormen van de spelervaring en tegelijkertijd helderheid en overzicht geven.  Er is ooit een digitale app geweest die als controller diende in het spel en die is ook weer volledig over boord gegooid.
<br/><br/>
Dit eindeloos experimenteren, nieuwe mogelijkheden ontdekken en de schoonheid vinden in deze gevonden mogelijkheden is wat mij drijft als ontwerper. Ik geloof echter dat het bordspel Atano nu op het punt is gekomen dat het niet alleen interessant voor mij is, maar ook voor de buitenwereld. Ik besef echter dat een goed uitgekristaliseerd idee nog geen product is. Je zult ervaren mensen nodig hebben die erin geloven en het tot een consumenten product kunnen maken. Ik hoop dat u dat kan zijn. 
                </div>
            </div>
        </div>
    )
}

export default Ontwerper